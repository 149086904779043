import React from 'react'

const ProducSubHead = ({desc, title}) => {
  return (
    <div className='w-full flex flex-col gap-5 sm:gap-10 text-center px-10'>
        <p className='text-black text-xs sm:text-base font-semibold'>{desc}</p>
        <h4 className='text-3xl font-bold' style={{color:'#0050ae'}}>{title}</h4>
    </div>
  )
}

export default ProducSubHead