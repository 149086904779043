import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileNav";
import DesktopLogo from "../svgs/DesktopLogo";
import { navLinks, productLinks } from "../../mock/data";
import expand from "../../assets/icons/expand.svg";
import Button from "../Resuseables/Button/Button";

const Header = () => {
  const [showDropDown, setShowDropDown] = useState(false);

  const handleDropdown = () => {
    setShowDropDown(!showDropDown);
  };

  return (
    <header className="w-full flex items-center justify-between pt-8  px-4  sm:px-10 xl:px-28">
      <Link to="/" className="w-auto relative">
        <div className="hidden lg:block">
          <DesktopLogo />
        </div>
      </Link>

      <nav className="hidden lg:flex ">
        <ul
          className="flex gap-6 xl:gap-10 font-bold"
          style={{ color: "#0050ae" }}
        >
          {navLinks.map((items, idx) => (
            <li
              key={idx}
              className="relative cursor-pointer flex items-center gap-4 hover:text-red-500"
              onClick={idx === 0 ? handleDropdown : null}
            >
              <Link to={items.url}>{items.label}</Link>

              
              {idx === 0 && (
                <span onClick={handleDropdown}>
                  <img src={expand} alt="" />
                </span>
              )}

              {idx === 0 && showDropDown && (
                <div className="shadowBg absolute w-full top-full  z-50 bg-white">
                  <div className="h-full flex flex-col p-2">
                    {productLinks.map((link, index) => (
                      <p key={index} className="cursor-pointer hover:underline">
                        <Link to={link.url}>{link.label}</Link>
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </nav>









      <div className="hidden lg:flex items-center font-medium gap-4">
        <Link to="https://app.sleengshort.com/login">
          <Button btnText="Log in" btnColor="#0050ae" />
        </Link>

        <Link to="https://app.sleengshort.com/register">
          <Button
            btnText="Sign up"
            btnBgColor="#0050ae"
            btnColor="#fff"
            btnRadius="28px"
            btnPadding="7px 40px"
          />
        </Link>
      </div>

      <MobileMenu />
    </header>
  );
};

export default Header;
