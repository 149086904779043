import React from "react";
import ProducSubHead from "../../../../components/Resuseables/ProductSubHead/ProducSubHead";
import { bulksmsContnet } from "../../../../mock/data";
import ProductCards from "../../../../components/Resuseables/ProductCards/ProductCards";
// import HeroLogo from "../../../../components/svgs/HeroLogo";
import ProductPricing from "../../../../components/Resuseables/ProductPricing/ProductPricing";
import Button from "../../../../components/Resuseables/Button/Button";
import { Link } from "react-router-dom";

const BulksmsContent = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center gap-10 relative">
      <ProducSubHead
        desc={
          "Welcome to Sleengshorts Bulk SMS Service – Elevate Your Messaging Experience with Tailored Solutions."
        }
        title={"Use Case :"}
      />

      <div className="w-full sm:w-4/5 md:w-3/5 lg:w-3/5 mx-auto">
        {bulksmsContnet.map((card, index) => (
          <div key={index} className="w-full mb-8">
            <ProductCards title={card.tag} desc={card.desc} icon={card.icon} />
          </div>
        ))}
      </div>

      <Link to={"https://app.sleengshort.com/register"}>
        <Button
          btnText={"Get Started"}
          btnBgColor={"#0050AE"}
          btnColor={"white"}
          btnRadius={"19px"}
          btnPadding={"8px 28px"}
        />
      </Link>

      {/* <HeroLogo className="absolute top-0 inset-0 w-full object-cover z-0" /> */}
    </div>
  );
};

export default BulksmsContent;
