import React, { useState } from "react";
import { reviews } from "../../../mock/data";
import peopleUk2 from "../../../assets/images/peopleUk2.png";
import Button from "../../../components/Resuseables/Button/Button";
import BluePaperPlane from "../../../components/svgs/BluePaperPlane";
import RedPaperPlane from "../../../components/svgs/RedPaperPlane";
import CurlyPlane from "../../../components/svgs/CurlyPlane";
import ladyBg from "../../../assets/images/womanBg.png";
import { Link } from "react-router-dom";
import VideoPlayer from "../VideoPlayer";

const CustomersReviews = () => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <section className="w-full h-full text-center mb-[8rem] sm:mb-0 md:pt-10  flex flex-col gap-20 ">
      <div
        className="w-4/5 h-full flex flex-col lg:flex-row justify-center gap-10  mx-auto my-20 lg:my-28"
        style={{ color: "#0050ae" }}
      >
        <div className="max-w-2xl flex flex-col gap-10  md:text-left ">
          <h1
            className="md:text-[40px] font-bold"
            style={{ lineHeight: "1.2" }}
          >
            Ready to Transform the Way You Communicate?
          </h1>
          <div className="flex flex-col gap-5">
            <p className="font-bold">
              Take your business communication to the next level with
              Sleengshort. Whether you’re reaching thousands or engaging
              one-on-one, our powerful bulk SMS and messaging solutions ensure
              your voice is heard, loud and clear Join the growing list of
              businesses that trust Sleengshort to deliver results—seamlessly,
              securely, and with a personal touch.
            </p>
            <p>
              Join the growing list of businesses that trust Sleengshort to
              deliver results—seamlessly, securely, and with a personal touch.
            </p>
          </div>

          <div>
            <Link to="https://app.sleengshort.com/login">
              <Button
                btnText="Start Here"
                btnBgColor="#0050ae"
                btnColor="#fff"
                btnRadius="28px"
                btnPadding="10px 40px"
              />
            </Link>
          </div>
        </div>

        <div className="ladyReview relative w-full max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl mx-auto">
          <img src={peopleUk2} alt="" className="w-full h-auto object-cover" />

          <div className="arr-2 absolute top-0 right-0 md:right-10 lg:right-20 transform -translate-y-1/4">
            <RedPaperPlane />
          </div>

          <div className="arr-1 absolute top-1/2 right-0 md:right-5 transform translate-y-[-50%]">
            <BluePaperPlane />
          </div>

          <div className="arr-3 absolute bottom-0 left-0 md:left-10 transform translate-y-1/4">
            <CurlyPlane />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomersReviews;
