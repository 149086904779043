import React from "react";
import SectionHeading from "../../../components/Resuseables/Subheading/SectionHeading";

const OurVision = () => {
  return (
    <div
      className="w-full flex flex-col gap-10 justify-between px-4 sm:px-10 xl:px-28 py-28 text-white"
      style={{ backgroundColor: "#DCE7FE", color: "#0050ae" }}
    >
      <SectionHeading title={"Our Values"} />

      <div className="w-full flex items-center justify-center ">
        <ul>
          {vision.map((item, idx) => (
            <li
              key={idx}
              className="flex flex-col md:flex-row items-center md:gap-10  pb-10"
            >
              <p className="font-semibold">
                <span
                  className=""
                  style={{
                    height: "8px",
                    width: "8px",
                    backgroundColor: "#0050ae",
                    borderRadius: "50%",
                    display: "inline-block",
                    marginRight:'10px',
                  }}
                ></span>

                {item.left}
              </p>
              <p className="text-center">{item.right}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OurVision;

export const vision = [
  {
    id: 1,
    left: "Innovation:",
    right:
      "Embrace creativity and continuous improvement to provide cutting-edge solutions.",
  },
  {
    id: 2,
    left: "Reliability:",
    right:
      "Uphold a commitment to consistency, dependability, and delivering on promises.",
  },
  {
    id: 3,
    left: "Customer- Centricity:",
    right:
      "Prioritize the needs and satisfaction of customers in all aspects of business.",
  },
  {
    id: 4,
    left: "Integrity:",
    right:
      "Operate with honesty, transparency, and ethical standards in every interaction.",
  },
  {
    id: 5,
    left: "Flexibility:",
    right:
      "Adapt to changing environments, technologies, and client requirements with agility.",
  },
];
