import React from "react";
import ProductHeader from "../../../../components/Resuseables/ProductsHeader/ProductHeader";
import voiceImg from "../../../../assets/images/voicehead.png";

const VoiceTop = () => {
  return (
    <div className="w-full flex flex-col items-center  gap-10 text-white">
      <ProductHeader title={"VOICE"} />
    </div>
  );
};

export default VoiceTop;
