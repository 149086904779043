import React from "react";
import footerBg from "../../../assets/images/footerbg.png";
import SectionHeading from "../../../components/Resuseables/Subheading/SectionHeading";

const OurStory = () => {
  return (
    <div
      className="w-full  flex flex-col items-center justify-center px-4 sm:px-10 xl:px-28 py-28 text-white"
      style={{
        backgroundImage: `url(${footerBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="w-full lg:w-4/5 flex flex-col gap-10 lg:flex-row items-center lg:items-start justify-center">
        <div className="w-full">
          <SectionHeading title={"Our Story"} />
        </div>

        <div className="w-full  ">
          <p className="text-center lg:text-left">
            The tech ecosystem in the United Kingdom is growing really fast and
            there are a lot of activities around sales, marketing and
            communication. Being able to interact with customers on the go has
            become very important in todays business world.
            <br />
            <br />
            However, as important as communication is, it can also be quite
            complex. Targeting the right audience or communicating with the
            right customer can be difficult sometimes. Sleengshort offers
            customers an already made platform that simplifies every aspect of
            communication. With sleengshort, you are able to reach the right
            audience at the right time without dealing with the complexities.
            Whether you want to quickly send messages on the go or integrate
            with our API to deliver messages to customers, we are able to
            provide the right tools for you to do it seamlessly
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
