import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SectionHeading from "../../components/Resuseables/Subheading/SectionHeading";
import OurStory from "./OurStory";
import OurMission from "./OurMission";
import OurVision from "./OurVision";

const AboutPage = () => {
  return (
    <section className="w-full h-screen flex flex-col overflow-x-hidden">
      <Header />
      <div className="my-20 sm:my-28 md:my-32 lg:my-40">
        <SectionHeading title={"About US"} color={"#0050ae"} fSize={50} />
      </div>

      <OurStory />
      <OurMission />
      <OurVision />
      <Footer />
    </section>
  );
};

export default AboutPage;
