import React, { useState } from 'react'
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import Button from '../../../components/Resuseables/Button/Button'
import webinarImg from '../../../assets/images/webinar.png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

const Webinar = () => {
    const navigate = useNavigate()
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [role, setRole] = useState('');
    const [country, setCountry] = useState('');


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const myHeaders = new Headers();
            myHeaders.append("api-auth-key", "sleenghort_1234567890987654321");
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "fullName": fullName,
                "email": email,
                "phone": phone,
                "companyName": companyName,
                "country": country,
                "role": role
            });


            //   const response = await axios.post(
            //     "http://api.tapestry.ng:1234/api/v1/sleeng/webinar/sendForm",  
            //     { fullName, email, phone, companyName, country, role },
            //     {
            //       headers: {
            //         "Content-Type": "application/json",
            //         "api-auth-key": 'sleenghort_1234567890987654321',
            //       },
            //     }
            //   );

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("http://api.tapestry.ng:1234/api/v1/sleeng/webinar/sendForm", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    // console.log(result)
                    if (result.status === "ok") {
                        alert("Thanks for requesting a reminder please check your email for invite and details")
                        navigate("/")
                        setFullName("")
                        setEmail("")
                        setPhone("")
                        setCompanyName("")
                        setCountry("")
                        setRole("")
        
                    } else {
                        alert("Error sending your request");
                    }
                })
                .catch((error) => console.error(error));

            
        } catch (error) {
            console.error("Error:", error);
        }
    }
    return (
        <section className="w-full h-screen flex flex-col overflow-x-hidden">
            <Header />
            <div>
                <img src={webinarImg} alt='webinar' />
            </div>
            <div>
                <div className='flex flex-col justify-center w-full items-center'>
                    <p className='my-4 text-[#0050ae] text-center'>Driving Business Success with Integrated Communication Solutions</p>
                    <h1 className='text-3xl font-semibold text-[#0050ae]'>Get a Reminder</h1>
                </div>
                <form action="" onSubmit={handleFormSubmit} className='flex my-10 items-center flex-col justify-center gap-6 md:gap-10 text-black w-full'>
                    {/* <div className='w-full flex items-center gap-6 md:gap-10'> */}
                    <input type="text" placeholder='Full Name' className='lg:w-2/6 px-4 py-2 outline-none bg-[#F0F8FF]' value={fullName} onChange={(e) => setFullName(e.target.value)} required />
                    {/* </div> */}

                    {/* <div className='w-full flex items-center gap-6 md:gap-10'> */}
                    <input type="email" placeholder='Email Address' className='lg:w-2/6 px-4 py-2 outline-none bg-[#F0F8FF]' value={email} onChange={(e) => setEmail(e.target.value)} required />
                    {/* </div> */}

                    {/* <div className='w-full flex items-center gap-6 md:gap-10'> */}
                    <input type="text" placeholder='Phone Number' className='lg:w-2/6 px-4 py-2 outline-none bg-[#F0F8FF]' value={phone} onChange={(e) => setPhone(e.target.value)} required />
                    {/* </div> */}

                    <input type="text" placeholder='Company Name' className='lg:w-2/6 px-4 py-2 outline-none bg-[#F0F8FF]' value={companyName} onChange={(e) => setCompanyName(e.target.value)} required />


                    <input type="text" placeholder='Role' className='lg:w-2/6 px-4 py-2 outline-none bg-[#F0F8FF]' value={role} onChange={(e) => setRole(e.target.value)} required />

                    <input type="text" placeholder='Country' className='lg:w-2/6 px-4 py-2 outline-none bg-[#F0F8FF]' value={country} onChange={(e) => setCountry(e.target.value)} required />



                    {/* <div className='flex justify-center cursor-pointer'> */}
                    <Button
                        btnText="Notify me"
                        btnBgColor="#0050ae"
                        btnColor="#fff"
                        btnRadius="5px"
                        btnPadding="10px 40px"
                    />
                    {/* </div> */}

                    <div>

                    </div>
                </form>

            </div>
            {/* <Faq /> */}
            {/* <QueriesAndComplaints /> */}
            <Footer />
        </section>
    )
}

export default Webinar