




const SectionHeading = ({ title, subtitle, fSize, color }) => {
  return (
    <div
      className="mx-auto text-center text-2xl sm:text-3xl md:text-[40px] max-w-md md:max-w-none md:break-words"
    >
      <p style={{ fontSize: "14px" }}>{subtitle}</p>
      <h1 className="" style={{ color: color, fontWeight: "bold", fontSize: fSize }}>
        {title}
      </h1>
    </div>
  );
};

export default SectionHeading;
