import React from "react";
import bgImg from "../../../assets/images/footerbg.png";

const ProductHeader = ({ title, headerImg }) => {
  return (
    <div
      className="w-full min-h-[200px] sm:min-h-[250px] md:min-h-[280px] lg:min-h-[320px] flex items-center gap-4 sm:gap-10 px-4 sm:px-10 xl:px-28 py-16 sm:py-20 lg:py-28 text-white relative"
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        marginBottom:"3rem"
      }}
    >
      <div className="w-full flex justify-center sm:justify-between relative">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-[70px] font-bold px-4 sm:px-8 lg:px-16">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default ProductHeader;
