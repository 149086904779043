import messaging from "../assets/icons/messaging.svg";
import puzzleMatching from "../assets/icons/puzzleMatchIcon.svg";
import sentIcon from "../assets/icons/sentIcon.svg";
import moneyBox from "../assets/icons/moneyBox.svg";
import mobileTexting from "../assets/icons/mobiletexting.svg";
import hastagLarge from "../assets/icons/hashtagLarge.svg";
import dollarCoin from "../assets/icons/dollarCoin.svg";
import voice from "../assets/icons/voice.svg";
import johnAgu from "../assets/images/john-agu.png";
// import Theresa from "../assets/images/theresa.png";
import Ben from "../assets/images/ben.png"
import smart from "../assets/images/smartHive.png";
import star from "../assets/icons/stars.svg";
import smspc from "../assets/images/smspc.svg";
import smsbell from "../assets/images/smsbell.svg";
import smspush from "../assets/images/smspush.svg";
import smsleave from "../assets/images/smsleave.svg";
import smsapproval from "../assets/images/smsapproval.svg";
import ussdTP from "../assets/images/ussdTP.svg";
import ussdMB from "../assets/images/ussdMB.svg";
import ussdFAQ from "../assets/images/ussdFAQ.svg";
import ussdTups from "../assets/images/ussdTups.svg";
import ussdUI from "../assets/images/ussdUI.svg";
import airtimeprice from "../assets/images/airtimeprice.svg";
import airtimetrust from "../assets/images/airtimetrust.svg";
import airtimegiftbox1 from "../assets/images/airtimegiftbox1.svg";
import airtimegiftbox2 from "../assets/images/airtimegiftbox2.svg";

export const navLinks = [
  { id: 2, label: "Products", url: "" },
  { id: 3, label: "About Us", url: "/about" },
  { id: 4, label: "Developer", url: "https://sleengshort.gitbook.io/sleengshort-sms-api-documentation/" },
  { id: 5, label: "Support", url: "/faq" },
];

export const productLinks = [
  { id: 1, label: "Bulksms", url: "/bulksms" },
  { id: 4, label: "Voice", url: "/voice" },
];

export const customersExp = [
  {
    id: 1,
    icon: messaging,
    title: "Efficient Communication ",
    desc: "Deliver important messages to your customers with ease and efficiency.",
  },
  {
    id: 2,
    icon: puzzleMatching,
    title: "Simple Integration",
    desc: "Our API was built to eliminate all complexities. Integration to our platform is seamless and easy.",
  },
  {
    id: 3,
    icon: sentIcon,
    title: "Delivery Guaranteed",
    desc: "Our cutting edge technology and DND look up feature guarantees our customers the highest delivery rate in the market.",
  },
  {
    id: 4,
    icon: moneyBox,
    title: "Cost Savings",
    desc: "Reach out directly to your customers with customized messages using customized sender IDs for the best delivery rates.",
  },
];

export const ourProducts = [
  {
    id: 1,
    icon: mobileTexting,
    tag: "SMS",
    desc: "Integrate with our sms API and deliver messages to your clients from your application or use our bulk sms feature to communicate directly with your clients in 3 easy steps. Sign up to get started now.",
  },
  {
    id: 4,
    icon: voice,
    tag: " Voice",
    desc: "We have a ready to use online call center application that can support any organization no matter the scale,our call center can support large corporations or small businesses.",
  },
];

export const reviews = [
  {
    id: 1,
    img: johnAgu,
    name: "John Agu",
    job: "Developer",
    comment:
      "Using Sleengshort's communication solutions has been a game-changer for our business! The Bulk SMS service is reliable and efficient, helping us reach our customers instantly. Highly recommend Sleengshort for anyone looking to enhance their communication strategy",
    star: star,
  },
  {
    id: 2,
    img: Ben,
    name: "Ben  Ling",
    job: "General Manager African Region Montnet",
    comment:
      "Sleengshort's Voice broadcasting service has significantly improved our customer engagement. The platform is user-friendly, and the support team is always responsive to our needs. It's a pleasure working with Sleengshort!",
    star: star,
  },
  {
    id: 3,
    img: smart,
    name: "Peace Irabor",
    job: "Co-Founder || Smarthivetech Solutions",
    comment:
      "We found it easy to integrate with Sleengshort's SMS aggregation service and their delivery rate has been great.Their customer support is fantastic too, always prompt and helpful.Overall, I highly recommend Sleengshort to anyone looking to expand their offerings.",
    star: star,
  },

 
  {
    id: 4,
    img: johnAgu,
    name: "John Agu",
    job: "Developer",
    comment:
      "I can't say enough good things about Sleengshort! Their Two-way SMS service has transformed how we communicate with our audience. The platform is intuitive, and the analytics provided help us make data-driven decisions. Sleengshort is a valuable partner for our business.",
    star: star,
  },
  {
    id: 5,
    img: Ben,
    name: "Ben  Ling",
    job: "General Manager African Region Montnet",
    comment:
      "Sleengshort's Call Center solutions have revolutionized our customer support operations. The system is robust, and the features are customizable to fit our needs perfectly. Our team efficiency has increased significantly since implementing Sleengshort.",
    star: star,
  },
  {
    id: 6,
    img: johnAgu,
    name: "John Agu",
    job: "Developer",
    comment:
      "Using Sleengshort's communication solutions has been a game-changer for our business! The Bulk SMS service is reliable and efficient, helping us reach our customers instantly. Highly recommend Sleengshort for anyone looking to enhance their communication strategy",
    star: star,
  },
  {
    id: 7,
    img: Ben,
    name: "Ben  Ling",
    job: "General Manager African Region Montnet",
    comment:
      "Sleengshort's Voice broadcasting service has significantly improved our customer engagement. The platform is user-friendly, and the support team is always responsive to our needs. It's a pleasure working with Sleengshort!",
    star: star,
  },
  {
    id: 8,
    img: smart,
    name: "Peace Irabor",
    job: "Co-Founder || Smarthivetech Solutions",
    comment:
      "We found it easy to integrate with Sleengshort's SMS aggregation service and their delivery rate has been great.Their customer support is fantastic too, always prompt and helpful.Overall, I highly recommend Sleengshort to anyone looking to expand their offerings.",
    star: star,
  },
  {
    id:9,
    img: johnAgu,
    name: "John Agu",
    job: "Developer",
    comment:
      "I can't say enough good things about Sleengshort! Their Two-way SMS service has transformed how we communicate with our audience. The platform is intuitive, and the analytics provided help us make data-driven decisions. Sleengshort is a valuable partner for our business.",
    star: star,
  },
  {
    id: 10,
    img: Ben,
    name: "Ben  Ling",
    job: "General Manager African Region Montnet",
    comment:
      "Sleengshort's Call Center solutions have revolutionized our customer support operations. The system is robust, and the features are customizable to fit our needs perfectly. Our team efficiency has increased significantly since implementing Sleengshort.",
    star: star,
  },
  {
    id: 11,
    img: johnAgu,
    name: "John Agu",
    job: "Developer",
    comment:
      "Using Sleengshort's communication solutions has been a game-changer for our business! The Bulk SMS service is reliable and efficient, helping us reach our customers instantly. Highly recommend Sleengshort for anyone looking to enhance their communication strategy",
    star: star,
  },
  {
    id: 12,
    img: Ben,
    name: "Ben  Ling",
    job: "Director || Montnets",
    comment:
      "Sleengshort's Voice broadcasting service has significantly improved our customer engagement. The platform is user-friendly, and the support team is always responsive to our needs. It's a pleasure working with Sleengshort!",
    star: star,
  },
  {
    id: 13,
    img: smart,
    name: "Peace Irabor",
    job: "Co-Founder || Smarthivetech Solutions",
    comment:
      "We found it easy to integrate with Sleengshort's SMS aggregation service and their delivery rate has been great.Their customer support is fantastic too, always prompt and helpful.Overall, I highly recommend Sleengshort to anyone looking to expand their offerings.",
    star: star,
  },
  {
    id: 14,
    img: johnAgu,
    name: "John Agu",
    job: "Developer",
    comment:
      "I can't say enough good things about Sleengshort! Their Two-way SMS service has transformed how we communicate with our audience. The platform is intuitive, and the analytics provided help us make data-driven decisions. Sleengshort is a valuable partner for our business.",
    star: star,
  },
  {
    id: 15,
    img: Ben,
    name: "Ben Ling",
    job: "General Manager African Region Montnet",
    comment:
      "Sleengshort's Call Center solutions have revolutionized our customer support operations. The system is robust, and the features are customizable to fit our needs perfectly. Our team efficiency has increased significantly since implementing Sleengshort.",
    star: star,
  },
];

export const questionsType = [
  { id: 1, type: "GENERAL INFORMATION" },
  { id: 2, type: "ACCOUNT INFORMATION" },
  { id: 3, type: "BULK SMS" },
  { id: 4, type: "VOICE" },
  { id: 5, type: "AIRTIME" },
  { id: 6, type: "CUSTOMER SUPPORT" },
];

export const questionsAndAnswers = [
  {
    id: 1,
    question: "How do you open an account ",
    answer:
      'To create an account, please visit "https://app.sleengshort.com/register" and provide your username, email, password, and phone number. After filling in the required information, click on "SIGN UP" to complete the registration process. Once your account is successfully created, you will be prompted to log in immediately. ',
  },
  {
    id: 2,
    question: "How do i get a sender ID ",
    answer:
      "To register a Sender ID, please note that the Sender ID must have a relationship with the name of the company. The registration request for the Sender ID is sent to each telecommunications company (telco). In order for your Sender ID(s) to be successfully registered, you are required to submit your Certificate of Incorporation and Letters of Authorization using the company templates provided by us. These templates will be shared with you upon request. For further instructions and assistance, please contact our support team at support@sleengshort.com.",
  },
  {
    id: 3,
    question: "How do i send bulk SMS ?",
    answer:
      "To access the Send SMS functionality, you can navigate to your user dashboard or find it in the SMS drop-down menu within the user interface. This feature enables you to send bulk SMS messages efficiently, provided that you have a valid Sender ID.  When sending an SMS, there are three key pieces of information required: Valid Sender ID: A Sender ID is a unique identifier that represents the sender of the SMS. It can be a brand name, company name, or any custom alphanumeric string. To send SMS messages, you must have a valid Sender ID associated with your account. This ensures that the recipient recognizes the source of the message and builds trust in the communication. Recipient Data: To send bulk messages, you need to provide the recipient data. This includes the phone numbers or contact information of the individuals or groups you wish to reach with your SMS campaign. The recipient data can be in various formats, such as a CSV file, an Excel spreadsheet, or an API call. It's important to ensure that the recipient data is accurate and up to date to ensure successful delivery of your messages. Message: The content of your SMS message is also required. This includes the actual text or information you want to convey to your recipients. You can include important details, promotions, notifications, or any other relevant information within the message. It's essential to craft concise and engaging messages that effectively deliver your intended message to the recipients.  By providing a valid Sender ID, recipient data, and crafting a compelling message, you can leverage the Send SMS feature to send bulk SMS messages efficiently. This enables you to engage with your audience, deliver important information, and facilitate effective communication through the power of SMS messaging.",
  },
  // {
  //   id: 4,
  //   question: "How can fund my account",
  //   answer:
  //     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo itaque obcaecati placeat dolore doloremque est.",
  // },
  // {
  //   id: 5,
  //   question: "How do i speak to a customer care rep",
  //   answer:
  //     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo itaque obcaecati placeat dolore doloremque est.",
  // },
  // {
  //   id: 6,
  //   question: "How do i use the USSD service",
  //   answer:
  //     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo itaque obcaecati placeat dolore doloremque est.",
  // },
  // {
  //   id: 7,
  //   question: "How do i use the Voice feature ",
  //   answer:
  //     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo itaque obcaecati placeat dolore doloremque est.",
  // },
];

export const bulksmsContnet = [
  {
    id: 2,
    icon: smsbell,
    tag: "Promotional Campaigns",
    desc: "Boost your marketing efforts with targeted promotional messages. Reach a wide audience instantly and drive engagement with exclusive offers and promotions.",
  },
  {
    id: 3,
    icon: smspush,
    tag: "Transactional Alert",
    desc: " Ensure your customers stay informed with transactional alerts, from order confirmations to account updates, Sleengshort keeps your users in the loop.",
  },
  {
    id: 4,
    icon: smsleave,
    tag: "Event Reminder",
    desc: "Seamlessly coordinate events by sending timely reminders to participants. Enhance attendance and participation with our event-focused Bulk SMS solutions.",
  },
  {
    id: 5,
    icon: smsapproval,
    tag: "Appointment Confirmation",
    desc: "Reduce no-shows and streamline scheduling with automated appointment confirmations. Keep your appointments organized and your clients informed.",
  },
];

export const usdContent = [
  {
    id: 1,
    icon: ussdTP,
    tag: "Customer Survey",
    desc: "Boost your marketing efforts with targeted promotional messages. Reach a wide audience instantly and drive engagement with exclusive offers and promotions.",
  },
  {
    id: 2,
    icon: ussdMB,
    tag: "Mobile Banking",
    desc: " Ensure your customers stay informed with transactional alerts, from order confirmations to account updates, Sleengshort keeps your users in the loop.",
  },
  {
    id: 3,
    icon: ussdFAQ,
    tag: "Information Retrieval",
    desc: "Seamlessly coordinate events by sending timely reminders to participants. Enhance attendance and participation with our event-focused Bulk SMS solutions.",
  },
  {
    id: 4,
    icon: ussdTups,
    tag: "Voting & Polls",
    desc: "Reduce no-shows and streamline scheduling with automated appointment confirmations. Keep your appointments organized and your clients informed.",
  },
  {
    id: 5,
    icon: ussdUI,
    tag: "Promotional Campaigns",
    desc: "Boost your marketing efforts with targeted promotional messages. Reach a wide audience instantly and drive engagement with exclusive offers and promotions.",
  },
];

export const airtimeContent = [
  {
    id: 1,
    icon: airtimeprice,
    tag: "Employee Reward",
    desc: "Boost your marketing efforts with targeted promotional messages. Reach a wide audience instantly and drive engagement with exclusive offers and promotions.",
  },
  {
    id: 1,
    icon: airtimetrust,
    tag: "Customer Loyalty",
    desc: " Ensure your customers stay informed with transactional alerts. From order confirmations to account updates, Sleengshort keeps your users in the loop.",
  },
  {
    id: 1,
    icon: smspc,
    tag: "Promotional Campaigns",
    desc: "Boost your marketing efforts with targeted promotional messages. Reach a wide audience instantly and drive engagement with exclusive offers and promotions.",
  },
  {
    id: 1,
    icon: airtimegiftbox1,
    tag: "Survey Incentives",
    desc: "Reduce no-shows and streamline scheduling with automated appointment confirmations. Keep your appointments organized and your clients informed.",
  },
  {
    id: 1,
    icon: airtimegiftbox2,
    tag: "Gifts & Giveaways",
    desc: "Boost your marketing efforts with targeted promotional messages. Reach a wide audience instantly and drive engagement with exclusive offers and promotions.",
  },
];

export const voiceContent = [
  {
    id: 1,
    icon: smsbell,
    tag: "Promotional Campaigns",
    desc: "Boost your marketing efforts with targeted promotional messages. Reach a wide audience instantly and drive engagement with exclusive offers and promotions.",
  },
  {
    id: 1,
    icon: smspush,
    tag: "Transactional Alert",
    desc: " Ensure your customers stay informed with transactional alerts. From order confirmations to account updates, Sleengshort keeps your users in the loop.",
  },
  {
    id: 1,
    icon: smsleave,
    tag: "Event Reminder",
    desc: "Seamlessly coordinate events by sending timely reminders to participants. Enhance attendance and participation with our event-focused Bulk SMS solutions.",
  },
  {
    id: 1,
    icon: smsapproval,
    tag: "Appointment Confirmation",
    desc: "Reduce no-shows and streamline scheduling with automated appointment confirmations. Keep your appointments organized and your clients informed.",
  },
];
