import React from "react";
import { ourProducts } from "../../../mock/data";
import SectionHeading from "../../../components/Resuseables/Subheading/SectionHeading";

const OurProducts = () => {
  return (
    <section className="ourProduct w-full h-auto text-center px-4 sm:px-10 xl:px-28 py-20">
      {/* <h1 className='text-white text-4xl '>Our Products</h1> */}
      <SectionHeading title={"Our Products"} color={"#fff"} />

      <div className="w-full h-auto flex flex-col items-center justify-center pt-20">
        <div className="max-w-5xl w-full px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap justify-center gap-10">
            {ourProducts.map((item, idx) => (
              <div
                className="w-[90%] sm:w-[400px] min-h-[350px] bg-white rounded-2xl text-left px-8 flex flex-col items-start gap-4 pt-8 shadow-md"
                key={idx}
              >
                <img src={item.icon} alt="" className="w-12 h-12" />
                <h4 className="text-3xl font-bold text-[#0050ae]">
                  {item.tag}
                </h4>
                <p className="text-sm font-medium">{item.desc}</p>
              </div>
            ))}
          </div>
        </div>

        {/* <div className='grid  grid-cols-2 grid-rows-2 md:grid-cols-none md:flex flex-wrap justify-center items-center gap-5 sm:gap-10'>
    {ourProducts.map((item, idx) => (
      <div className='w-[160px] max-h-[260px] sm:w-[260px] sm:min-h-[315px] bg-white rounded-2xl text-left px-8 flex flex-col items-start gap-4 pt-8' key={idx}>
        <img src={item.icon} alt="" className='w-12 h-12'/>
        <h4 className='text-3xl font-bold ' style={{color:'#0050ae'}}>{item.tag}</h4>
        <p className='text-sm font-thin'>{item.desc}</p>
      </div>
    ))}
  </div> */}
      </div>
    </section>
  );
};

export default OurProducts;
